// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Carousel from 'antd/lib/carousel'
import 'antd/lib/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import { updateWebsiteState as updateWebsiteStateFx } from '../state/actions'

import AboutPageSchema from '../components/schema/about-page-schema'
import ContactPageSchema from '../components/schema/contact-page-schema'

import ArrowLeft from '../components/svg/arrow-left'
import ArrowRight from '../components/svg/arrow-right'

import about from '../seo/about.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'About',
  slug: '/about',
  abstract: about.text,
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'About', slug: '/about' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "book/img1.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img2: file(relativePath: { eq: "book/img2.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ websiteState, updateWebsiteState, ...props }) => {
  const resources = [{ img: props.data.img1 }, { img: props.data.img2 }]

  const sliderConf = {
    infinite: false,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    customPaging: (i) => {
      return (
        <a aria-label={`Go to slide ${i + 1}`} role="link">
          <GatsbyImage image={getImage(resources[i].img)} />
        </a>
      )
    },
    dotPosition: 'bottom',
  }

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...props}>
      <AboutPageSchema />
      <ContactPageSchema />
      <h1>About this book</h1>
      <p className="dropcap">
        <span>M</span>other came to live with Sri Aurobindo in 1920. They worked
        together to bring the next stage of human evolution. They both
        experienced the new light and force which was coming to enable human
        beings to have the consciousness and the power of the Supreme
        All-Consciousness and All-Love, the Creation itself. At that time, this
        consciousness was beginning to enter the subtle levels of creation which
        are accessible to man.
      </p>
      <p>
        They worked in themselves to receive this new consciousness, and then
        they helped other people to receive it. They created a system called
        ‘Integral Yoga’, to work on every aspect of ourselves, from the highest
        subtle mental level down to the physical body, because the new
        consciousness transforms every part of the human being. The eventual
        result is that man will become the new being that is our next step in
        evolution. Sri Aurobindo called the new consciousness ‘Supramental’,
        because it transforms man into a being immeasurably beyond the mental
        man, the most highly evolved being on earth today.
      </p>
      <p>
        This change is entirely new, and therefore completely unknown. Sri
        Aurobindo worked until December 5, 1950, when he voluntarily left his
        body to continue bringing the supramental consciousness into the
        creation without being hampered by the still-existing limitations of his
        body. The supramental force he had accumulated in his body passed into
        Mother’s body. Mother focused on receiving the new force and its
        transformation of her own being. As part of working for the supramental
        transformation of humanity, Mother described her experiences of the new
        force and the changes it brought into her physical body. She described
        all the work she did, and all the work that we have to do, as a total,
        unconditional surrender to the Supreme All-Consciousness which will do
        the work in us.
      </p>
      <p>
        She said that her mental functions and her vital life-energy-emotional
        functions, the more subtle parts of her being, could most easily rise
        into and receive the new supramental consciousness and force, but the
        force had to be brought down into them in their functions in the body.
        This was much more difficult. The mental and vital had some resistance,
        but not like the physical body. The physical body was so inert and
        unconscious that by its very inertness, it was the most resistant to
        change.
      </p>
      <p>
        Mother’s first general experience of the supramental light and
        consciousness coming down to earth was on February 29, 1956. Her first
        personal experience was on September 12, 1956, and she saw it radiating
        out from her to enter into people around her. On October 17, 1957, she
        understood the mechanism of transformation into the new being to be the
        awakening of the body’s cells. On February 3, 1958, she experienced the
        permanent existence of the supramental world, and the construction of
        the link between the supramental world and our world in the physical
        realm, where the supramental light, power and substance was constantly
        permeating matter. On July 24-25, 1959, she had the first penetration of
        the supramental force into her own body.
      </p>
      <p>
        For the next fourteen years, until April 23, 1973, eight months before
        Mother left her body, she continued to speak about receiving the
        supramental force and how it changed her body and all of its more subtle
        processes. She described the progress of her body’s physical cells, and
        said they had a pure and loving consciousness which gladly accepted the
        new force when they understood. She spoke of what she called “the change
        of government” of all the parts of her being, as their complete control
        was gradually taken up by the Supreme All-Consciousness and the Supreme
        Will.
      </p>
      <p>
        Mother’s descriptions are part of her conversations with a disciple
        about a wide variety of subjects. They were recorded and transcribed,
        and published in thirteen volumes called Mother’s Agenda. The Mother’s
        Yoga” is compiled from conversations in“Mother’s Agenda”. It contains
        Mother’s conversations about her own integral yoga to receive the
        supramental force and how it changed her.
      </p>
      <p>
        <Link to="/introduction">Continue reading...</Link>
      </p>
      <h2>About Integral Yoga Talks</h2>
      <p>
        This book is part of the Integral Yoga Talks project. The Integral Yoga
        Talks project is a website offering which will publish talks by Loretta
        Shartsis on Sri Aurobindo and Mother, both in audio and video, and will
        also make available Loretta’s book compilations as web-books. The
        project is scheduled to be released in stages from 2022–24 under the
        umbrella of Prisma’s new publications format of gift-economy publishing.
      </p>
      <p>
        Over the years, Loretta has given public talks on Sri Aurobindo and
        Mother both in Auroville and abroad, and has recorded two series of
        talks for Auroville Radio. She has published four book-compilations of
        their works. These on-going efforts will now be available worldwide on
        the website{' '}
        <Link to="https://www.integral-yoga-talks.com/en/">
          www.integral-yoga-talks.com
        </Link>
        .
      </p>
      <h2>About Loretta Shartsis</h2>
      <p>
        Loretta Shartsis came to the Sri Aurobindo Ashram in February 1972,
        because she wanted to meet Mother. It was Sri Aurobindo’s 100th
        Centenary year. Loretta met Mother in March and the next day, without
        knowing how it happened, she found herself living at the centre of
        Auroville, with her own room in the Matrimandir Worker’s Camp. From that
        time, her life has been devoted to Mother and Sri Aurobindo. Fifty years
        later, for Sri Aurobindo’s 150th Birth Centenary, the world will have
        the results of her dedicated teaching about Mother and Sri Aurobindo.
      </p>
      <p>
        Loretta came to Mother after growing up with a life of work and
        education as a visual artist. Later she became an attorney, doing
        Supreme Court and Court of Appeals Research and Presentation and court
        trials. In her teaching work she combines a life of aesthetic pursuits
        and the high quality research and public presentation required in a
        court of law.
      </p>
      <h2>The print book</h2>
      <div className="slider fullpage book-slider flow">
        <Carousel {...sliderConf}>
          {resources.map((resource) => (
            <GatsbyImage image={getImage(resource.img)} />
          ))}
        </Carousel>
      </div>
      <h2>Web book, this format</h2>
      <p>
        <i>{about.altTitle}</i>&nbsp;is published as part of an emerging method
        of publishing being developed by Prisma Books. The idea is to deliver
        the high level of writing and design quality that you would find in a
        printed book, and to use advanced web technologies for publishing. This
        is the "web-book" format.
      </p>
      <p>
        <Link to="https://books.prisma.haus/en/web-books">
          Read more about web books →
        </Link>
      </p>
      <h2 id="on-gift-economy">On gift economy</h2>
      <p>
        You can buy the paperback version of the book <i>{about.altTitle}</i> at
        a fixed price. The author and the publisher have also chosen to offer
        the book to read online without a pay-wall or advertisement. This is not
        for free, but available on a Gift-Economy and Pay-What-Feels-Right
        basis. It is an invitation to give what feels right for the value you've
        received from the book and/or the support you want to give us to
        continue doing our work. Publishing in this manner is an experiment in
        abundance where we trust that when we give, we will also receive. We
        want you to enjoy the book, learn from the book, but also support the
        book. If it was worth your time, please consider buying the book or
        contributing.
      </p>
      <p>
        <Link to="https://books.prisma.haus/en/available-on-gift-economy">
          Learn more about publishing on Gift-Economy →
        </Link>
      </p>
      <h2>Prisma Books, the publisher</h2>
      <p>
        Since 1988 Prisma Books of Auroville publishes good old-fashioned,
        beautifully curated books. Prisma Books offers full publishing services
        in design, editorial, printing, artwork, illustrations, photography,
        digital media, exhibition and curatorship.
      </p>
      <p>
        <Link to="https://www.prisma.haus/en/">Prisma website →</Link>
      </p>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    websiteState: state.websiteState,
  }),
  (dispatch) => ({
    updateWebsiteState(payload) {
      dispatch(updateWebsiteStateFx(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
